
import { Options, Vue } from "vue-class-component";
import CV from "@/views/CV.vue";
import Home from "@/views/Home.vue";

@Options({
  components: {
    CV,
    Home,
  },
})
export default class AppColorful extends Vue {
  currentPhotoColor = "black";
  private page: "Home" | "CV" = "Home";
  // private opacity = 0;

  get appStyle(): Record<string, string> {
    return { background: this.currentPhotoColor };
  }

  // handleOpacityChangeInHome(newOpa: number) {
  //   this.opacity = newOpa;
  // }
}
