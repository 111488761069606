
import { Options, Vue } from "vue-class-component";
import SlickTimeline from "@/components/slickCV/slickTimeline/SlickTimeline.vue";
import { Prop } from "vue-property-decorator";
import { Resume } from "@/components/slickCV/types";
import { trafoFunctions } from "@/components/slickCV/use/useResumeToTimeline";
import { TimeLineItem } from "@/components/slickCV/slickTimeline/types";
import Nugget from "@/components/slickCV/Nugget.vue";

@Options({
  components: { SlickTimeline, Nugget }
})
export default class SlickCV extends Vue {
  @Prop({ required: true }) resume!: Resume;
  private technoSwitches: Record<string, boolean> = {};
  private hoveringOnTechnoBox = false;

  get allTechnos() {
    const allNuggets: Set<string> = new Set<string>();
    this.sortedTimelineItems.forEach((tlitem) => {
      tlitem.nuggets?.forEach((nugget) => {
        allNuggets.add(nugget);
      });
    });
    return allNuggets;
  }

  get titleOfTechnoBox(): string {
    return this.hoveringOnTechnoBox ? "right click for single toggle!" : "Filters";
  }

  get sortedFilteredTimelineItems(): TimeLineItem[] {
    let items = this.sortedTimelineItems.filter((tl) => this.hasActiveTechno(tl));
    console.table(items);
    return items;
  }

  get sortedTimelineItems(): TimeLineItem[] {
    const res: TimeLineItem[] = [];

    for (const key in trafoFunctions) {
      const fun = trafoFunctions[key];
      const arr = (this.resume as any)[key];
      console.log(`building ${key}-items`);
      for (let i = 0; i < arr.length; i++) {
        res.push(fun(arr[i]));
      }
    }
    return res.sort((a, b) => -a.from.getTime() + b.from.getTime());
  }

  created() {
    this.allTechnos.forEach((t) => this.activateTechno(t));
  }

  isActive(tec: string): boolean {
    // if (this.technoSwitches[tec]==undefined){
    //   this.activateTechno(tec);
    // }
    return this.technoSwitches[tec];
  }

  technoClicked(tec: string) {
    this.technoSwitches[tec] = !this.technoSwitches[tec];
    // this.$forceUpdate();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.timeline.refresh();
  }

  contextMenuCB(e: any) {
    console.log(typeof e);
    e.preventDefault();
  }

  technoRightClicked(tec: string) {
    const isOn = this.technoSwitches[tec];
    for (let technoSwitchesKey in this.technoSwitches) {
      this.technoSwitches[technoSwitchesKey] = !isOn;
    }
    this.technoSwitches[tec] = isOn;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.timeline.refresh();
  }

  private activateTechno(tec: string) {
    this.technoSwitches[tec] = true;
  }

  private hasActiveTechno(tl: TimeLineItem): boolean {
    return tl.nuggets?.some((n) => this.isActive(n)) ?? false;
  }
}
