
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { TimeLineItem } from "@/components/slickCV/slickTimeline/types";
import TimeLineCellIcon from "@/components/slickCV/slickTimeline/TimeLineCellIcon.vue";
import Nugget from "@/components/slickCV/Nugget.vue";

export default defineComponent({
  name: "TimeLineCell",
  components: { TimeLineCellIcon, Nugget },
  props: {
    indexRatio: { type: Number, required: true },
    item: { type: Object as () => TimeLineItem, required: true },
    show: { type: Boolean, required: true },
  },

  computed: {
    largeScreen(): boolean {
      return this.width > 424242; // TODO using the small screen version for all screens for now.
    },
    hasLink(): boolean {
      return this.item.textLink?.length > 0 ?? false;
    },
    dateTextColorStyle(): Record<string, any> {
      const interp = (a: number, b: number, ratio: number) =>
        a + (b - a) * ratio;
      const col1 = [0, 15, 30];
      const col2 = [222, 164, 146];
      const col3 = [141, 217, 181];
      let red;
      let green;
      let blue;
      if (this.indexRatio < 0.5) {
        let ratio1 = this.indexRatio * 2;
        red = interp(col1[0], col2[0], ratio1);
        green = interp(col1[1], col2[1], ratio1);
        blue = interp(col1[2], col2[2], ratio1);
      } else {
        let ratio2 = (this.indexRatio - 0.5) * 2;
        red = interp(col2[0], col3[0], ratio2);
        green = interp(col2[1], col3[1], ratio2);
        blue = interp(col2[2], col3[2], ratio2);
      }
      return {
        color: `rgb(${red},${blue},${green})`,
      };
    },
  },
  setup(props) {
    const rootElement = ref(null);
    const title = ref(props.item.title);

    let windowWidth = ref(window.innerWidth);

    const onWidthChange = () => (windowWidth.value = window.innerWidth);
    onMounted(() => window.addEventListener("resize", onWidthChange));
    onUnmounted(() => window.removeEventListener("resize", onWidthChange));
    const width = computed(() => windowWidth.value);

    return { rootElement, title, width };
  },
  methods: {
    showNormalTitle() {
      this.title = this.item.title;
    },
    showAltTitle() {
      this.title = this.item.titleAlt ?? this.item.title;
    },
    goToLink() {
      if (this.hasLink) {
        window.open(this.item.textLink, "_blank");
      }
    },
    getRootElem() {
      return this.rootElement;
    },
    makeDateString: function (dateStr: string, date: Date) {
      function date2string() {
        const pad = (n: number): string => ("" + n).padStart(2, "0");
        let month = pad(date.getMonth() + 1);
        let day = pad(date.getDate());
        let year = date.getFullYear();
        return `${year}-${month}-${day}`;
      }

      if (dateStr.match(/\d{4}\/\d{2}\/\d{2}/) != null) {
        return date2string();
      } else {
        return dateStr;
      }
    },
    makeTillString: function (): string {
      const date = this.item.till;
      let dateStr = this.item.tillStr ?? "ongoing";
      if (date.getTime() > new Date().getTime()) dateStr = "ongoing";
      return this.makeDateString(dateStr, date);
    },
    makeFromString: function (): string {
      const date = this.item.from;
      const dateStr = this.item.fromStr ?? "?";
      return this.makeDateString(dateStr, date);
    },
  },
});
