
import { defineComponent } from "vue";
import { TimeLineItem } from "@/components/slickCV/slickTimeline/types";

export default defineComponent({
  name: "TimeLineCellIcon",
  props: {
    item: { type: Object as () => TimeLineItem, required: true },
  },
});
