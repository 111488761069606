
import { Options, Vue } from "vue-class-component";
import HelloWorld from "@/components/HelloWorld.vue";
import { Watch } from "vue-property-decorator"; // @ is an alias to /src

@Options({
  components: {
    HelloWorld,
  },
})
export default class Home extends Vue {
  private showImg = false;
  private windowWidth: number = window.innerWidth;
  private opacity = 0;
  private maxOpacity = 0.5;
  private opacityMobile = 0.3;

  // @Watch('opacity')
  // watchOpacity(oldOpa:number,newOpa:number){
  //   console.log(newOpa);
  //   this.$emit('newOpacity',newOpa);
  // }

  get mobile(): boolean {
    return this.windowWidth < 1000;
  }

  calcOpacity(event: { clientX: number; clientY: number }): void {
    if (this.mobile) {
      this.opacity = this.opacityMobile;
      return;
    }
    let x_contribution = Math.sin(
      (event.clientX * Math.PI) / window.innerWidth
    );
    let y_contribution = Math.sin(
      ((event.clientY + window.scrollY) * Math.PI) / window.innerHeight
    );
    this.opacity = this.maxOpacity * x_contribution * y_contribution;
  }

  created() {
    this.showImg = this.mobile;
  }

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    if (this.mobile) this.opacity = this.opacityMobile;
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize() {
    this.windowWidth = window.innerWidth;
    this.showImg = this.mobile;
  }
}
