import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { href: "mailto:nikolauskoopmann@gmail.com" }
const _hoisted_2 = { class: "headerBlock name" }
const _hoisted_3 = { class: "headerBlock headerRight" }
const _hoisted_4 = { href: "https://www.linkedin.com/in/nikolaus-koopmann-447444107/" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Home = _resolveComponent("Home")!
  const _component_CV = _resolveComponent("CV")!

  return (_openBlock(), _createBlock("div", { style: _ctx.appStyle }, [
    _createVNode("div", {
      class: [{ headerAndFooterYmargined: _ctx.page === 'Home' }, "slickFont headerAndFooter header"]
    }, [
      _createVNode("div", {
        class: "headerBlock headerLeft btn",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.page = 'Home'))
      }, [
        _createVNode("a", _hoisted_1, [
          _createVNode(_component_font_awesome_icon, { icon: ['fas', 'envelope'] })
        ])
      ]),
      _withDirectives(_createVNode("div", _hoisted_2, " Nikolaus Koopmann ", 512), [
        [_vShow, _ctx.page === 'Home']
      ]),
      _createVNode("div", _hoisted_3, [
        _createVNode("a", _hoisted_4, [
          _createVNode(_component_font_awesome_icon, { icon: ['fab', 'linkedin'] })
        ])
      ])
    ], 2),
    _createVNode(_component_Home),
    _createVNode(_component_CV, { firstColor: _ctx.currentPhotoColor }, null, 8, ["firstColor"])
  ], 4))
}