<template>
  <div class="about">

    <h1 style="margin-top: 15em">This is an about page</h1>
  </div>
</template>




<style type="text/css" scoped>
.about {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
}
</style>