
import { defineComponent, onBeforeUpdate, onMounted, ref } from "vue";
import { TimeLineItem } from "@/components/slickCV/slickTimeline/types";
import TimeLineCell from "@/components/slickCV/slickTimeline/TimeLineCell.vue";

export default defineComponent({
  name: "SlickTimeline",
  components: { TimeLineCell },
  props: { items: { type: Object as () => TimeLineItem[], required: true } },
  methods: {
    refresh() {
      for (let i = 0; i < this.show.length; i++) {
        this.show[i] = false;
      }
      for (let i = 0; i < 6; i++) {
        this.show[i] = true;
      }
      // this.$forceUpdate;
    }
  },
  setup(props) {
    const show = ref(Array(props.items.length).fill(false));
    const cells = ref([]);
    const callback = (
      intersectionOBserverEntryArray: IntersectionObserverEntry[]
    ) => {
      intersectionOBserverEntryArray.forEach(
        (entry: IntersectionObserverEntry) => {
          const id = Number(entry.target.id);
          const intersects: boolean = entry.isIntersecting;
          show.value[id] = intersects;
        }
      );
    };

    let options = {
      root: document.querySelector("#scrollArea"),
      rootMargin: "0px",
      threshold: 0
    };

    let observer = new IntersectionObserver(callback, options);

    onMounted(() => {
      for (let i = 0; i < props.items.length; i++) {
        const target = cells.value[i];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        observer.observe(target.getRootElem());
      }
    });

    onBeforeUpdate(() => {
      cells.value = [];
    });

    return { cells, show, callback };
  }
});
