
import { Options, Vue } from "vue-class-component";
import resumeData from "@/assets/resume.json";
import SlickCV from "@/components/slickCV/SlickCV.vue";
import { Prop } from "vue-property-decorator";

@Options({
  components: {
    SlickCV
  }
})
export default class CV extends Vue {
  @Prop({ required: true }) firstColor!: string;
  private resume: Record<string, any> = {};

  get colormapStyle(): Record<string, any> {
    return { backgroundImage: `linear-gradient(${this.firstColor}, #fdd279, #acc172, #67a77a, #36897e, #286873, #2f4858)`,
    backgroundColor: this.firstColor};
  }


  created() {
    this.resume = resumeData;
  }
}
