
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
})
export default class Nugget extends Vue {
  @Prop({ required: true }) nugget!: string;
  @Prop({ required: false, default: true }) active!: boolean;
}
